<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑说明' : '新增说明'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<a-form-item name="title" label="说明类型" >
						{{ transType(modelRef.code) }}
					</a-form-item>
					
					<a-form-item name="content" label="说明内容" :rules="[{ required: true, message: '请输入说明内容!' }]">
						<Editor v-model:value="modelRef.content"></Editor>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import Editor from '@/components/editor/editor.vue';
	import { updatePlatText } from '@/service/modules/platform.js';
	export default {
		components: { Icon, modalHeader, Editor },
		data() {
			return {
				visible: false,
				isEdit: false,
				modelRef: {
					content: ''
				},
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					// postData.type = 3;
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await updatePlatText(postData);
						} else {
							// ret = await saveBanner(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = JSON.parse(JSON.stringify(e));
					// this.getDetail(e.id);
					this.isEdit = true;
				} else {
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.resetFields();
				this.modelRef = {};
				this.visible = false;
			},
			transType(code) {
				let text = '';
				switch(code) {
					case 'PLATFORM': 
						text = '平台说明';
						break;
				}
				return text;
			}
		}
	}
</script>

<style scoped>
	.ui-upload__warn {
		/* margin-top: 6px; */
		margin-bottom: 0;
		font-size: 12px;
		color: #999;
	}
</style>