<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<!-- <a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button> -->
					<a-button @click="onSearch">						<template #icon><Icon icon="ReloadOutlined"/></template>						刷新</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="标题" name="title" class="ui-form__item">
						<a-input v-model:value="formModal.title" placeholder="请输入标题"></a-input>
					</a-form-item>
					
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<div style="margin-top: 20px;">
				<a-table class="table"
					rowKey="id"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1000 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'img'">
							<a-image style="width: 60px;" :src="record.img"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_agreement_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<saveModal ref="saveModal" @refresh="onReset"></saveModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import { getPlatTextList } from '@/service/modules/platform.js';
	export default {
		components: { Icon, saveModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					type: 3,
				},
				list: [],
				clearTime: [], // 存储时间 用来清空重置
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 100
				}, {
					title: '协议类型',
					align: 'center',
					dataIndex: "title"
				}, {
					title: '操作',
					key: 'action',
					width: 80,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			async getData() {
				this.loading = true;
				try {
					let ret = await getPlatTextList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {type: 3}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.pagination.current = 1;
				this.getData();
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onEdit(item) {
				this.$refs.saveModal.open(item);
			},
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
</style>