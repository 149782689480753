import { send } from "../index";
// 获取Banner列表
export function getBannerList(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 获取Banner详情
export function getBannerDetail(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 新增Banner
export function saveBanner(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 编辑Banner
export function updateBanner(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/update.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 删除Banner
export function deleteBanner(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 下架Banner
export function pullOffShelvesBanner(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 上架Banner
export function pullOnShelvesBanner(data, opts = {}) {
  return send({
    url: "/admin/baseBanner/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 协议列表
export function getPlatTextList(data, opts = {}) {
  return send({
    url: "/admin/platText/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 编辑协议
export function updatePlatText(data, opts = {}) {
  return send({
    url: "/admin/platText/update.do",
    method: "POST",
    data,
    ...opts,
  });
}